<template>
    <v-container style="max-width:450px" class="mt-10">
        <v-row>
            <v-row v-show="showBooking" align="center" justify="center">
                <v-col >
                    <v-card min-width="450px" class="pa-10">
                        <v-card-title class="justify-center">Upcoming Appointment</v-card-title>

                        <template>
                            <v-data-table :headers="bookingHeaders" :items="bookingItems" :hide-default-footer="true">
                                <template v-slot:item="row">
                                    <tr>
                                        <td>{{row.item.Date}}</td>
                                        <td>{{row.item.Time}}</td>
                                        <td>
                                            <v-btn class="mx-2" dark small color="pink" @click="onCancelClick(row.item)">
                                                Cancel
                                            </v-btn>
                                        </td>
                                        <td>
                                            <v-btn v-show="showEdit" class="mx-2" dark small color="blue" @click="onEditClick(row.item)">
                                                Edit
                                            </v-btn>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </template>

                    </v-card>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col >
                    <v-card min-width="450px" class="pa-10">
                        <v-card-title class="justify-center">Pickup Date</v-card-title>
                        <v-row justify="center">
                            <v-col>
                                <v-menu
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="computedDateFormatted"
                                        label="Please choose a date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        @blur="selectDate = parseDate(dateFormatted)"
                                        v-on="on"
                                        required
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker 
                                    v-model="selectDate"
                                    @input="menu2 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>            
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row align="center"  justify="center">
                <v-col >
                    <v-card min-width="450px" class="mt-10 pa-10">
                        <v-card-title class="justify-center">Pickup Time</v-card-title>
                        <v-row justify="center">
                            <v-col>
                                <v-combobox
                                    v-model="selectHour"
                                    :items="items"
                                    label="Please choose a time"
                                    outlined
                                    dense
                                ></v-combobox>
                                <span style="color:red" v-if="hourErrorMsg">{{ hourErrorMsg }}</span>
                            </v-col>            
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row align="center"  justify="center" >
                <v-card min-width="450px" flat class="mt-10">
                    <div class="pa-10">
                        <v-btn large block color="primary" dark :disabled="submitButtonDisabled"
                            @click="finalConfirm(
                                {
                                    bookingDate: selectDate,
                                    bookingHour: selectHour
                                }
                            )"
                        >
                            Submit
                        </v-btn>
                    </div>
                </v-card>
            </v-row>
        </v-row>

        <v-dialog
        v-model="openFinalDialog"
        max-width="350"
        >
        <v-card>
            <v-card-title class="headline">
            Confirmation
            </v-card-title>

            <v-card-text class= text-center>
                <h3>Date : {{ selectDate }}</h3>
            </v-card-text>
            <v-card-text class= text-center>
                <h3>Time : {{ selectHour }}</h3>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="green darken-1"
                text
                @click="returnDialog(false)"
            >
                Cancel
            </v-btn>

            <v-btn class="ml-20"
                color="green darken-1"
                text
                @click="returnDialog(true)" 
            >
                Send Request
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

    </v-container>
</template>
<script>

    import { mapState } from 'vuex';

    export default{
        data: vm =>  ({
            selectDate: new Date().toISOString().substr(0,10),
            dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
            menu: false,
            modal: false,
            menu2: false,
            showEdit: false,
            showBooking: false,
            submitButtonDisabled: false,
            selectHour: null,
            items: [
                '9AM-12AM',
                '1PM-5PM',
            ],
            hourErrorMsg: null,
            openFinalDialog: false,
            dialogResult: false,
            
            headers: [
                { text: 'no', value: 'no' },
                { text: 'result', value: 'result' },
                { text: 'good', value: 'good'},
            ],
                // in real case initial search_result = [], and methods: search function inject below data
            appointmentItems: [{no: 0, result: 'aaa'}, {no:2, result: 'bbb'}],

            bookingHeaders: [
                { text: 'Date', value: 'Date' },
                { text: 'Time', value: 'Time' },
                { text: 'Cancel', value: 'Cancel'},
            ],
                // in real case initial search_result = [], and methods: search function inject below data
            bookingItems: [],


        }),
        mounted() {

            console.log(this.$store.state.bookingInfo);
            if (  this.$store.state.bookingInfo.length != null && this.$store.state.bookingInfo.length > 0 )
            {
                var i;
                for ( i=0; i < this.$store.state.bookingInfo.length; i++ )
                {

                    var item = {};
                    var $apptDate = new Date(this.$store.state.bookingInfo[i].AppointmentDate);
                    item['Date'] = $apptDate.getMonth()+1 + "/" + $apptDate.getDate() + "/" + $apptDate.getFullYear();
                    item['Time'] = this.$store.state.bookingInfo[i].AppointmentTimeBegin;
                    item['ID'] = this.$store.state.bookingInfo[i].ID;
                    var $Time_End = this.$store.state.bookingInfo[i].AppointmentTimeEnd;
                    if (item['Time'] != $Time_End)
                    {
                        item['Time'] = item['Time'] + '-' + $Time_End;
                    }

                    this.bookingItems.push(item);
                }
                this.showBooking = true;

                if (this.$store.state.bookingInfo.length >= 5 )
                {
                    this.submitButtonDisabled = true;
                }
                else{
                    this.submitButtonDisabled = false;
                }
            }
            
        },
        beforeUpdate() 
        {
            /*
                if ( this.$store.state.bookingInfo.length!= null && this.$store.state.bookingInfo.length > 0 )
                    {
                        this.bookingItems.length = 0;

                        console.log( 'length : ' + this.$store.state.bookingInfo.length);
                        var i;
                        for ( i=0; i < this.$store.state.bookingInfo.length; i++ )
                        {

                            var item = {};
                            var $apptDate = new Date(this.$store.state.bookingInfo[i].AppointmentDate);
                            item['Date'] = $apptDate.getMonth()+1 + "/" + $apptDate.getDate() + "/" + $apptDate.getFullYear();
                            item['Time'] = this.$store.state.bookingInfo[i].AppointmentTimeBegin;
                            item['ID'] = this.$store.state.bookingInfo[i].ID;
                            var $Time_End = this.$store.state.bookingInfo[i].AppointmentTimeEnd;
                            if (item['Time'] != $Time_End)
                            {
                                item['Time'] = item['Time'] + '-' + $Time_End;
                            }

                            this.bookingItems.push(item);
                        }
                        this.showBooking = true;
                    }
                    */
        },
        computed: {
            computedDateFormatted () {
                return this.formatDate(this.selectDate)
            },
            ...mapState(['bookingInfo'])
        },
        watch: {
            selectDate()
            {
                this.dateFormatted = this.formatDate(this.date);
            },
            bookingInfo()
            {
                console.log("Watch bookingInfo");
                console.log(this.$store.state.bookingInfo.length);
                if (  this.$store.state.bookingInfo.length != null && this.$store.state.bookingInfo.length >= 0 )
                {
                    //this.bookingItems.length = 0;
                    this.bookingItems = [];
                    var i;
                    for ( i=0; i < this.$store.state.bookingInfo.length; i++ )
                    {

                        var item = {};
                        var $apptDate = new Date(this.$store.state.bookingInfo[i].AppointmentDate);
                        item['Date'] = $apptDate.getMonth()+1 + "/" + $apptDate.getDate() + "/" + $apptDate.getFullYear();
                        item['Time'] = this.$store.state.bookingInfo[i].AppointmentTimeBegin;
                        item['ID'] = this.$store.state.bookingInfo[i].ID;
                        var $Time_End = this.$store.state.bookingInfo[i].AppointmentTimeEnd;
                        if (item['Time'] != $Time_End)
                        {
                            item['Time'] = item['Time'] + '-' + $Time_End;
                        }

                        this.bookingItems.push(item);
                    }
                    this.showBooking = true;

                    if (this.$store.state.bookingInfo.length >= 5 )
                    {
                        this.submitButtonDisabled = true;
                    }
                    else{
                        this.submitButtonDisabled = false;
                    }

                }
            }
        },
        methods: {
            finalConfirm(parInfo)       
            {
                if ( parInfo.bookingHour === null )
                {
                    this.hourErrorMsg = "Please choose pickup time.";
                }
                else{
                    this.openFinalDialog = true;    // confirm dialog을 연다.
                }
            },
            submit(parInfo) {
                console.log(parInfo);
                if ( parInfo.bookingHour === null )
                {
                    this.hourErrorMsg = "Please choose pickup time.";
                }
                else{
                    this.$store.dispatch('submitBooking', parInfo);
                    
                }
            },
            returnDialog(result)
            {
                this.openFinalDialog = false;
                console.log(result);

                if ( result === true )
                {
                    const d = new Date()
                    const date = d.toISOString().split('T')[0];
                    const time = d.toTimeString().split(' ')[0];
                    let $CreateDate = `${date} ${time}`;

                    //console.log( "date => " +  $CreateDate);

                    this.submit({
                                    StoreCode: this.$store.state.userInfo.StoreCode,
                                    UserID: this.$store.state.userInfo.ID,
                                    BookingDate: this.selectDate,
                                    BookingTime: this.selectHour,
                                    Memo: null,
                                    CreateDate: $CreateDate,
                                    CancelDate: null
                                })
                }
                else{
                    result= false;
                }
            },
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            onCancelClick(item) {

                const d = new Date()
                const date = d.toISOString().split('T')[0];
                const time = d.toTimeString().split(' ')[0];
                let $cancelDate = `${date} ${time}`;

                this.$store.dispatch('cancelBooking', 
                                     {  
                                         bookingID :item.ID, 
                                         cancelDate : $cancelDate 
                                     });
            },
            onEditClick(item) {
                alert('Edit click on ' + item.no);
            },
        }
    }
</script>
